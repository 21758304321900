import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import { Banner } from '../acf/Banner';
import Layout from '../components/Layout';
import { decodeEntities } from '../utils/htmlParse';
import SEO from '../components/SEO';
import './post.scss';

const BlogPost = ({ data }) => {
  const { wordpressPost: post, site, location } = data;
  if (!post) return null;
  const { title: postTitle, content, yoast, featuredImage, categories, date, author } = post;
  const { title: siteTitle, wordpressUrl, blogSlug } = site.siteMetadata;
  const breadcrumbs = [
    {
      pageLink: `/${blogSlug}/`,
      crumbText: 'Blog'
    }
  ];
  const mainCategory = categories && categories.length > 0 ? categories[0] : null;
  return (
    <Layout location={location} className="post-page-template">
      <SEO
        title={`${
          yoast.metaTitle ?
          decodeEntities(yoast.metaTitle) :
          `${decodeEntities(postTitle)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      <Banner
        image={featuredImage}
        title={postTitle}
        wordpressUrl={wordpressUrl}
        breadcrumbs={breadcrumbs}
      >
        <div className="meta">
          {mainCategory && <span className="category" dangerouslySetInnerHTML={{__html: mainCategory.name}} />}
          <span className="posted">
            {`${date} `}
            - Posted by
            {` ${decodeEntities(author.name)}`}
          </span>
        </div>
      </Banner>
      <section className="post-container">
        <div className="wrapper">
          <div className="post-content-body" dangerouslySetInnerHTML={{__html: content}} />
        </div>
      </section>
    </Layout>
  )
}

export default Previewable(BlogPost)

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
        wordpressUrl
        blogSlug
        functionsUrl
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      author {
        name
        slug
      },
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`
